import React, {
  forwardRef,
  useState,
  useRef,
  useCallback,
  useLayoutEffect,
  createElement,
} from 'react'
import { useInView } from 'react-intersection-observer'
import { v4 as uuidv4 } from 'uuid'
import loadable from '@loadable/component'
import moment from 'moment'
import PropTypes from 'prop-types'
import { titleCase } from '../../utils'
import { featuredImageProps } from '../../proptypes'
import { pieceMetaProps } from '../../proptypes'

const templateMap = {
  blog: loadable(() => import('./BlogSummary')),
  music: loadable(() => import('./MusicSummary')),
}

const propsMap = {
  blog: (props) => {
    const {
      fields: { slug },
      frontmatter: { title, featuredImage, date },
      excerpt,
    } = props
    return { slug, title, featuredImage, date, excerpt, key: uuidv4() }
  },
  music: (props) => {
    const {
      fields: { slug },
      frontmatter: {
        title,
        pieceMeta: {
          instrumentation,
          compositionDate,
          duration,
          score,
          code,
          media,
        },
      },
      excerpt,
    } = props
    return {
      slug,
      title,
      instrumentation,
      compositionDate,
      duration,
      score,
      code,
      media,
      excerpt,
      key: uuidv4(),
    }
  },
}

const ArchiveSection = forwardRef((props, ref) => {
  const { type, category, posts } = props
  const initialViewRef = useRef(false)
  const [initialView, setInitialView] = useState(false)
  const [inViewRef, inView] = useInView({ rootMargin: '-50px' })

  const setRefs = useCallback(
    (node) => {
      // Ref's from useRef needs to have the node assigned to `current`
      ref.current = node
      // Callback refs, like the one from `useInView`, is a function that takes the node as an argument
      inViewRef(node)
    },
    [inViewRef, ref],
  )

  useLayoutEffect(() => {
    if (inView && !initialViewRef.current) {
      initialViewRef.current = true
      setInitialView(true)
    }
  }, [inView])

  const wrapperClasses = [
    'panel',
    'panel-archive',
    `panel-archive-${type}`,
    initialView ? 'rendered' : '',
    inView ? 'in-view' : '',
  ]
    .filter((i) => i)
    .join(' ')

  return (
    <section id={category} className={wrapperClasses} ref={setRefs}>
      <h2>{titleCase(category)}</h2>
      {posts.map((props) =>
        createElement(templateMap[type], propsMap[type](props)),
      )}
    </section>
  )
})

export const blogRawProps = PropTypes.shape({
  fields: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
  excerpt: PropTypes.string.isRequired,
  frontmatter: PropTypes.shape({
    title: PropTypes.string.isRequired,
    date: PropTypes.instanceOf(moment().constructor).isRequired,
    categories: PropTypes.array,
    primaryCategory: PropTypes.string.isRequired,
    featuredImage: featuredImageProps,
  }).isRequired,
})

export const musicRawProps = PropTypes.shape({
  fields: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
  frontmatter: PropTypes.shape({
    title: PropTypes.string.isRequired,
    categories: PropTypes.array,
    primaryCategory: PropTypes.string.isRequired,
    pieceMeta: PropTypes.shape(pieceMetaProps).isRequired,
  }).isRequired,
})

ArchiveSection.propTypes = {
  category: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  posts: PropTypes.arrayOf(PropTypes.oneOfType([blogRawProps, musicRawProps]))
    .isRequired,
}

export default ArchiveSection
