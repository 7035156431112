import React, { createRef, useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import NavContainer from '../components/NavContainer'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import HTMLContent from '../components/HTMLContent'
import ArchiveSection, {
  blogRawProps,
  musicRawProps,
} from '../components/Archive'
import moment from 'moment'
import {
  featuredImageProps,
  frontmatterProps,
  fieldsProps,
  siteProps,
  heroProps,
} from '../proptypes'
import { removeTrailingSlash, titleCase } from '../utils'

const ArchiveTemplate = ({
  hero,
  featuredImage,
  slug,
  subnav,
  html,
  posts,
  categories,
  postCount,
}) => {
  const pageClasses = [
    'content',
    'content-archive',
    `archive-${slug
      .split('/')
      .filter((i) => i)
      .join('-')}`,
    subnav && subnav.length ? 'has-subnav' : '',
  ]
    .filter((i) => i)
    .join(' ')

  const [categoryRefs] = useState(categories.map(() => createRef()))
  const heroRef = useRef()
  const type = slug
    .split('/')
    .filter((x) => x !== '/')
    .pop()

  return (
    <div id="content" className={pageClasses}>
      <NavContainer subnav={subnav} targetRefs={[heroRef, ...categoryRefs]} />
      <Hero {...hero} featuredImage={featuredImage} ref={heroRef} />
      <div className="main-wrapper">
        <main id="main" className="main">
          {!!html && <HTMLContent content={html} />}
          {!!postCount &&
            categories.map((category, i) => (
              <ArchiveSection
                type={type}
                category={category}
                posts={posts[category]}
                key={uuidv4()}
                ref={categoryRefs[i]}
              />
            ))}
        </main>
      </div>
    </div>
  )
}

ArchiveTemplate.propTypes = {
  hero: PropTypes.shape(heroProps),
  featuredImage: featuredImageProps,
  slug: PropTypes.string.isRequired,
  posts: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.oneOfType([blogRawProps, musicRawProps])),
  ).isRequired,
  postCount: PropTypes.number.isRequired,
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  subnav: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      refIndex: PropTypes.number.isRequired,
    }),
  ),
}

const ArchivePage = ({ data }) => {
  const {
    frontmatter: { hero, featuredImage },
    fields: { slug },
    html,
  } = data.markdownRemark
  const { location } = data.site.siteMetadata
  location.slug = removeTrailingSlash(slug)
  const postsByCategory = {}
  data.allMarkdownRemark.edges.forEach(({ node }) => {
    const categories = node.frontmatter.categories
    categories.forEach((category) => {
      if (!(category in postsByCategory)) {
        postsByCategory[category] = []
      }
      const newNode = { ...node }
      newNode.frontmatter.date = moment(newNode.frontmatter.date, 'MMM D, YYYY')
      postsByCategory[category].push(newNode)
    })
  })
  const pageProps = {
    hero,
    featuredImage,
    slug: location.slug,
    html,
    posts: postsByCategory,
    categories: Object.keys(postsByCategory).sort(),
    postCount: data.allMarkdownRemark.edges.length,
  }
  pageProps.subnav = pageProps.categories.map((key, i) => ({
    link: key
      .toLowerCase()
      .replace(/[^A-Za-z]/, '')
      .replace(' ', '-'),
    label: titleCase(key),
    refIndex: i,
  }))
  return (
    <Layout location={location} data={data}>
      <ArchiveTemplate {...pageProps} />
    </Layout>
  )
}

ArchivePage.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.shape({
    site: siteProps.isRequired,
    markdownRemark: PropTypes.shape({
      fields: fieldsProps.isRequired,
      frontmatter: frontmatterProps.isRequired,
    }).isRequired,
  }).isRequired,
}

export default ArchivePage

export const pageQuery = graphql`
  query ArchiveTemplate($id: String!, $archiveType: String!) {
    site {
      ...siteMeta
    }
    markdownRemark(id: { eq: $id }) {
      fields {
        slug
        gitAuthorTime
        gitCreatedTime
      }
      frontmatter {
        ...seoFields
        ...heroFields
        ...featuredImageFields
        template
      }
      html
    }
    allMarkdownRemark(
      filter: { frontmatter: { template: { eq: $archiveType } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          excerpt(format: PLAIN, pruneLength: 250)
          frontmatter {
            title
            date
            categories
            primaryCategory
            pieceMeta {
              instrumentation
              commissioner
              compositionDate
              duration
              score
              code
              media
            }
            featuredImage {
              alt
              src {
                childImageSharp {
                  fluid(maxWidth: 225, maxHeight: 225, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
